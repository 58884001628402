export default {
  login: {
    succeed: "loginSucceed",
    failed: "loginFailed",
    usernameRequired: "usernameRequired",
    passwordRequired: "passwordRequired"
  },
  logout: {
    succeed: "logoutSucceed",
    failed: "logoutFailed"
  },
  request: {
    succeed: "requestSucceed",
    failed: "requestFailed",
    processing: "requestProcessing"
  },
  session: {
    invalidToken: "invalidToken"
  }
};
