<template lang="pug">
  v-app
    .quiz-top(v-if="!quizStart")
      img(
        class="quiz-indicator"
        :src="require(`@/assets/images/quiz/quiz_level_2_${$store.getters.currentLocale}.png`)"
        @click="startQuiz"
      )
    .quiz-top(v-if="quizStart")
      .quiz-window
        .quiz-container(v-if="quizStep == 0")
          .h-100(v-html="$t('quiz.level.two.video')")
        .quiz-container(v-if="quizStep == 1")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[0].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.two.questions[0].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.two.questions[0].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.two.questions[0].options[2]') }}
        .quiz-container(v-if="quizStep == 2")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[1].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.two.questions[1].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.two.questions[1].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.two.questions[1].options[2]') }}
        .quiz-container(v-if="quizStep == 3")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[2].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.two.questions[2].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.two.questions[2].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.two.questions[2].options[2]') }}
        .quiz-container(v-if="quizStep == 4")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[3].question") }}
            .quiz-choices(:class="mobileView ? 'flex-column' : 'flex-row'")
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .label a. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_4_1.png")
                  b {{ $t('quiz.level.two.questions[3].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .label b. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_4_2.png")
                  b {{ $t('quiz.level.two.questions[3].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .label c. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_4_3.png")
                  b {{ $t('quiz.level.two.questions[3].options[2]') }}
        .quiz-container(v-if="quizStep == 5")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[4].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.two.questions[4].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.two.questions[4].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.two.questions[4].options[2]') }}
        .quiz-container(v-if="quizStep == 6")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[5].question") }}
            .quiz-choices.flex-column
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .text-label a. {{ $t('quiz.level.two.questions[5].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .text-label b. {{ $t('quiz.level.two.questions[5].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .text-label c. {{ $t('quiz.level.two.questions[5].options[2]') }}
        .quiz-container(v-if="quizStep == 7")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[6].question") }}
            .quiz-choices(:class="mobileView ? 'flex-column' : 'flex-row'")
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .label a. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_7_1.png")
                  b {{ $t('quiz.level.two.questions[6].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .label b. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_7_2.png")
                  b {{ $t('quiz.level.two.questions[6].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .label c. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_7_3.png")
                  b {{ $t('quiz.level.two.questions[6].options[2]') }}
        .quiz-container(v-if="quizStep == 8")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[7].question") }}
            .quiz-choices(:class="mobileView ? 'flex-column' : 'flex-row'")
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'a', 'correct': ((answer[quizStep] == 'a' && score[quizStep] == 0) || (answer[quizStep] == 'a' && score[quizStep])),'wrong': (choice[quizStep] == 'a' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'a')"
              )
                .label a. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_8_1.png")
                  b {{ $t('quiz.level.two.questions[7].options[0]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'b', 'correct': ((answer[quizStep] == 'b' && score[quizStep] == 0) || (answer[quizStep] == 'b' && score[quizStep])),'wrong': (choice[quizStep] == 'b' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'b')"
              )
                .label b. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_8_2.png")
                  b {{ $t('quiz.level.two.questions[7].options[1]') }}
              .quiz-answer(
                :class="{'selected': choice[quizStep] == 'c', 'correct': ((answer[quizStep] == 'c' && score[quizStep] == 0) || (answer[quizStep] == 'c' && score[quizStep])),'wrong': (choice[quizStep] == 'c' && answer[quizStep] != choice[quizStep] && score[quizStep] != null)}"
                @click="selectAnswer(quizStep, 'c')"
              )
                .label c. 
                .stacked-image
                  img(class="img-1" src="@/assets/images/quiz/level2/l2_8_3.png")
                  b {{ $t('quiz.level.two.questions[7].options[2]') }}
        .quiz-container(v-if="quizStep == 9")
          .quiz-header
            img(
              src="@/assets/images/quiz/quiz_header.png"
            )
            b Level 2
          .quiz-body
            .quiz-question {{ $t("quiz.level.two.questions[8].question") }}
            .quiz-choices(:class="mobileView ? 'flex-row' : 'flex-column'")
              .d-flex(:class="mobileView ? 'flex-column' : 'flex-row'")
                .m-auto(v-for="(element, index) in myArray" :key="element")
                  img(
                    class="drag-image p-1"
                    :src="element"
                  )
              draggable(
                v-if="score[9] == null"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                v-model="choice[9]"
              )
                transition-group(class="d-flex number-box" :class="mobileView ? 'flex-column' : 'flex-row'" type="transition" :name="!drag ? 'flip-list' : null")
                  .group(
                    v-for="i in choice[9]" :key="'image_' + i"
                  )  
                    b {{ i }}
              draggable(
                v-else
                v-bind="dragOptions2"
                v-model="answer[9]"
              )
                transition-group(class="d-flex number-box" :class="mobileView ? 'flex-column' : 'flex-row'")
                  .group(
                    v-for="i in answer[9]" :key="'image_' + i"
                    :class="{'correct': score[quizStep] == 1,'wrong': score[quizStep] == 0}"
                  )  
                    b {{ i }}
      v-btn(
        v-if="quizStep == 0"
        class="continue-button"
        @click="continueQuiz"
        color="primary"
      ) 
        b {{ $t('quiz.continue') }}
      v-btn(
        v-else-if="quizStep == 9 && score[quizStep] != null"
        class="continue-button"
        @click="saveQuiz"
        color="primary"
      ) 
        b {{ $t('quiz.level.two.complete') }}
      v-btn(
        v-else-if="score[quizStep] == null"
        class="continue-button"
        @click="submitAnswer(quizStep)"
        color="primary"
      ) 
        b {{ $t('quiz.answer') }}
      v-btn(
        v-else-if="score[quizStep] != null"
        class="continue-button"
        @click="nextQuestion()"
        color="primary"
      ) 
        b {{ $t('quiz.next_question') }}
</template>

<script>
import config from "@/config";
import errorTags from "@/errorTags";
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  computed: {
    mobileView() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    dragOptions2() {
      return {
        animation: 200,
        group: "description",
        disabled: true,
        ghostClass: "ghost"
      };
    }
  },
  data() {
    return {
      drag: false,
      myArray: [
        require("@/assets/images/quiz/level2/l2_9_1.png"),
        require("@/assets/images/quiz/level2/l2_9_2.png"),
        require("@/assets/images/quiz/level2/l2_9_3.png"),
        require("@/assets/images/quiz/level2/l2_9_4.png"),
        require("@/assets/images/quiz/level2/l2_9_5.png")
      ],
      quizStart: false,
      quizStep: 0,
      timeout: null,
      answer: {
        1: "c",
        2: "a",
        3: "a",
        4: "b",
        5: "b",
        6: "b",
        7: "b",
        8: "c",
        9: [5, 1, 2, 3, 4]
      },
      choice: {
        1: "",
        2: "",
        3: "",
        4: "",
        5: "",
        6: "",
        7: "",
        8: "",
        9: [1, 2, 3, 4, 5]
      },
      score: {
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,
        7: null,
        8: null,
        9: null
      },
      loading: false,
      feedback: {
        success: false,
        status: errorTags.request.failed
      }
    };
  },
  mounted() {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.quizStart = true;
    }, 2000);
  },
  methods: {
    startQuiz() {
      if (!this.quizStart) this.quizStart = true;
    },
    continueQuiz() {
      this.quizStep = 1;
    },
    selectAnswer(step, answer) {
      if (this.score[step] != null) return;
      else this.choice[step] = answer;
    },
    submitAnswer(step) {
      if (this.choice[step] == "") return;
      if (step == 9) {
        if (
          JSON.stringify(this.choice[step]) == JSON.stringify(this.answer[step])
        )
          this.score[step] = 1;
        else this.score[step] = 0;
      } else if (this.choice[step] == this.answer[step]) this.score[step] = 1;
      else this.score[step] = 0;
    },
    nextQuestion() {
      this.quizStep += 1;
    },
    saveQuiz() {
      let url = config.saveQuizUrl();

      var json = {
        userId: this.$store.getters.userId,
        level: 2,
        isDone: true,
        rawData: this.choice,
        score: Object.values(this.score).reduce((a, b) => {
          return a + b;
        }, 0),
        locale: this.$store.getters.currentLocale
      };

      this.loading = true;

      this.$http
        .post(url, json)
        .then(function(response) {
          this.loading = false;
          // Success
          try {
            if (response) {
              this.feedback.success =
                response.body.data ||
                this.$helpers.handleResponse(response.body.status)
                  ? true
                  : false;

              if (this.feedback.success) {
                this.$store.commit("customer/completeQuiz", { level: 2 });
                this.$router.push({
                  name: "Quiz3"
                });
              } else {
                this.$swal(
                  this.$t("error.save_quiz"),
                  response.body.error.message,
                  "error"
                );
              }
            }
          } catch (error) {
            this.feedback.success = false;
            this.$swal(
              this.$t("error.save_quiz"),
              response.body.error.message,
              "error"
            );
          }
        })
        .catch(response => {
          this.feedback.success = false;
          this.$swal(
            this.$t("error.save_quiz"),
            response.body.error.message,
            "error"
          );
        });
    }
  }
};
</script>

<style lang="sass" scoped>
.v-application
  min-height: 90vh !important
  overflow: hidden !important
</style>
