export default {
  appTitle: process.env.VUE_APP_TITLE,
  appVersion: process.env.VUE_APP_VERSION,
  baseUrl: process.env.VUE_APP_API,
  anybookUrl: process.env.VUE_APP_ANYBOOK_API,
  userBaseUrl: "/bo",
  filePath: "",
  //Configurations
  getStateListUrl() {
    return this.baseUrl + "/api/program/states";
  },
  getAreaListUrl() {
    return this.baseUrl + "/api/program/districts";
  },
  getSchoolListUrl() {
    return this.baseUrl + "/api/program/schools";
  },
  verifyCodeUrl() {
    return this.baseUrl + "/api/program/verifyCode";
  },
  loginUrl() {
    return this.baseUrl + "/api/user/login";
  },
  registerUrl() {
    return this.baseUrl + "/api/user/register";
  },
  forgotPwUrl() {
    return this.baseUrl + "/api/user/request";
  },
  resetPwUrl() {
    return this.baseUrl + "/api/user/reset";
  },
  saveMomUrl() {
    return this.baseUrl + "/api/mom/save";
  },
  saveVideoUrl() {
    return this.baseUrl + "/api/video/save";
  },
  saveQuizUrl() {
    return this.baseUrl + "/api/quiz/save";
  },
  drawPrizeUrl() {
    return this.baseUrl + "/api/prize/draw";
  }
};
